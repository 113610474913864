import { document, window } from '../globals';
import Attribution from './Attribution';
import UrlUtmBuilder from './Utm/UrlUtmBuilder';
import UtmzCookieUtmBuilder from './Utm/UtmzCookieUtmBuilder';
import urlDataService from '../analytics/UrlDataService';

// The current timestamp in seconds
const now = Math.round(Date.now() / 1000);
const endpoint = 'analytics/attribution';

const currentUrl = urlDataService.getFilteredUrl();

(async (): Promise<void> => {
    if (!window || !currentUrl) {
        return;
    }

    const currentURL = new URL(currentUrl.toString());
    let referrerUrl: URL | null = null;

    try {
        if (document?.referrer) {
            referrerUrl = new URL(document.referrer);
        }
    } catch (e) {
        referrerUrl = null;
    }

    const attribution = await Attribution.create(
        new UrlUtmBuilder(now, currentURL, referrerUrl),
        new UtmzCookieUtmBuilder(now),
        endpoint,
        currentURL.searchParams,
    );
    window.dataLayer.push({ currentAttribution: attribution.getAttribution() });

    // Used for debugging but will remain here while we evaluate and debug this live
    window.attribution = attribution;
})().catch(() => null);
