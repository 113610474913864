import Cookie from 'js-cookie';
import { z } from 'zod';
import fetch from '../api/fetch';
import { localStorage } from '../util/storage';
import type { Parameter } from './Utm';
import Utm from './Utm';
import UrlUtmBuilder from './Utm/UrlUtmBuilder';
import UtmRecord, { UtmRecordSchema } from './UtmRecord';
import type UtmzCookieUtmBuilder from './Utm/UtmzCookieUtmBuilder';
import { window } from '../globals';

type Attributions = Record<string, Utm>;
type AttributionsCollection = Record<'attributions', Attributions>;

const RemoteAttributionSchema = z.object({ attribution: UtmRecordSchema });

function assertRemoteAttributionResponse(data: unknown): asserts data is z.infer<typeof RemoteAttributionSchema> {
    RemoteAttributionSchema.parse(data);
}

const LocalAttributionSchema = z.object({ localAttribution: UtmRecordSchema.optional() });

function assertLocalAttributionResponse(data: unknown): asserts data is z.infer<typeof LocalAttributionSchema> {
    LocalAttributionSchema.parse(data);
}

export default class Attribution {
    private readonly checkoutCookieName = 'enteredCheckout';

    private readonly utmzCookieName = '__utmz';

    private utmzUtm?: Utm;

    private urlUtm?: Utm;

    private localAttribution?: UtmRecord;

    /**
     * @param endpoint The endpoint where remote attribution can be stored and retrieved
     */
    private constructor(
        private readonly urlUtmBuilder: UrlUtmBuilder,
        private readonly utmzUtmBuilder: UtmzCookieUtmBuilder,
        private readonly endpoint: string,
        private readonly searchParameters: URLSearchParams,
    ) {
        this.initUtmSources();
        this.persistAttributionLocally(this.determineCurrentAttribution());
    }

    /**
     * @param endpoint The endpoint where remote attribution can be stored and retrieved
     */
    public static async create(
        urlUtmBuilder: UrlUtmBuilder,
        utmzUtmBuilder: UtmzCookieUtmBuilder,
        endpoint: string,
        searchParams: URLSearchParams,
    ): Promise<Attribution> {
        const attribution = new Attribution(urlUtmBuilder, utmzUtmBuilder, endpoint, searchParams);

        await attribution.syncAttribution();

        return attribution;
    }

    private initUtmSources(): void {
        let utmzValue;
        try {
            utmzValue = Cookie.get(this.utmzCookieName);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        this.utmzUtm = this.utmzUtmBuilder.determineUtm(utmzValue);
        this.urlUtm = this.urlUtmBuilder.determineUtm();
    }

    public getAttribution(preferredAttribution = ''): Utm | null {
        const attribution = this.retrieveLocalAttribution();
        if (!attribution) {
            return null;
        }

        return attribution.getUtm(preferredAttribution)
            || attribution.getUtm('utmz')
            || attribution.getUtm('url');
    }

    private determineCurrentAttribution(): UtmRecord {
        const attribution = new UtmRecord();
        if (this.utmzUtm?.isKnown()) {
            attribution.setUtm(this.utmzUtm);
        }

        if (this.urlUtm?.isKnown()) {
            attribution.setUtm(this.urlUtm);
        }

        return attribution;
    }

    private persistAttributionLocally(utmRecord: UtmRecord): void {
        if (!utmRecord) {
            return;
        }

        const previousAttribution = this.retrieveLocalAttribution();

        // If we've already determined the attribution and entered the checkout
        // we don't overwrite the attribution
        if (this.hasEnteredCheckout() && previousAttribution.hasKnownUtm()) {
            return;
        }

        if (Attribution.isInCheckout()) {
            const oneHourFromNow = new Date(60 * 60 * 1e3 + Date.now());
            Attribution.setCookie(this.checkoutCookieName, '1', oneHourFromNow);
        }

        const parameters = Attribution.getOtherParametersFromUrlQuery(this.searchParameters);

        // Merge the newly determined attribution with the previously persisted attribution
        utmRecord.allUtms().forEach((utm) => {
            let newUtm = utm;

            // Unknown Utm values should never overwrite previously determined Utm
            if (!utm.isKnown()) {
                return;
            }

            // Skip unchanged utmz attribution so it is not enriched with the current URL parameters
            // The URL attribution will only be determined (again) if there are UTM parameters in the current URL
            // and in that case it should never be skipped
            const previousUtm = previousAttribution.getUtm(utm.determinedBy);
            const newUtmEqualsPreviousUtm = previousUtm instanceof Utm && previousUtm.equals(newUtm);
            if (utm.determinedBy === 'utmz' && newUtmEqualsPreviousUtm) {
                return;
            }

            if (parameters.length > 0) {
                newUtm = Attribution.enrichUtmWithParameters(utm, parameters);
            }

            previousAttribution.setUtm(newUtm);
        });

        // This value will be used a few times on this page
        // so we store it in memory for easier access
        this.localAttribution = previousAttribution;
        localStorage.setItem('local_attribution', JSON.stringify(previousAttribution));
    }

    private async syncAttribution(): Promise<void> {
        const localAttribution = this.retrieveLocalAttribution();
        const remoteAttribution = await this.getRemoteAttribution();

        // Don't send unknown attribution to be stored server-side
        if (!localAttribution.hasKnownUtm()) {
            if (remoteAttribution.hasKnownUtm()) {
                this.persistAttributionLocally(remoteAttribution);
            }

            return;
        }

        this.sendAttribution(localAttribution);
    }

    private async getRemoteAttribution() {
        let remoteAttribution: UtmRecord;

        // We retrieve any remote attribution if we don't have a cache yet
        // in case the local storage has been cleared
        if (Attribution.hasCachedRemoteAttribution()) {
            remoteAttribution = Attribution.getCachedRemoteAttribution();
        } else {
            try {
                remoteAttribution = await this.retrieveRemoteAttribution();
                if (remoteAttribution.hasKnownUtm()) {
                    Attribution.cacheRemoteAttribution(remoteAttribution);
                }
            } catch (e) {
                remoteAttribution = new UtmRecord();
            }
        }

        return remoteAttribution;
    }

    public retrieveLocalAttribution(): UtmRecord {
        // If we have already determined the attribution there is no need to reconstruct it from storage
        if (this.localAttribution instanceof UtmRecord) {
            return this.localAttribution;
        }

        const utmRecordJson = localStorage.getItem('local_attribution') ?? 'null';
        const utmRecordObject = JSON.parse(utmRecordJson) as AttributionsCollection | null;

        this.localAttribution = UtmRecord.fromObject(utmRecordObject);

        return this.localAttribution;
    }

    private async retrieveRemoteAttribution(): Promise<UtmRecord> {
        const response = await fetch(this.endpoint);
        const data = await response.json() as unknown;

        assertRemoteAttributionResponse(data);
        return UtmRecord.fromObject(data.attribution);
    }

    private static hasCachedRemoteAttribution(): boolean {
        return localStorage.getItem('remote_attribution') !== null;
    }

    private static getCachedRemoteAttribution(): UtmRecord {
        const utmJson = localStorage.getItem('remote_attribution') ?? 'null';

        return UtmRecord.fromObject(JSON.parse(utmJson));
    }

    private static cacheRemoteAttribution(utmRecord: UtmRecord): void {
        localStorage.setItem('remote_attribution', JSON.stringify(utmRecord));
    }

    private sendAttribution(attribution: UtmRecord): void {
        if (Attribution.hasCachedRemoteAttribution()) {
            const utmJson = JSON.stringify(attribution);
            const cachedRemoteAttribution = Attribution.getCachedRemoteAttribution();
            const cachedRemoteAttributionJson = JSON.stringify(cachedRemoteAttribution);

            // we only want to send the call if the attribution has changed
            // from what is already stored
            if (utmJson === cachedRemoteAttributionJson) {
                return;
            }
        }

        fetch(this.endpoint, {
            body: JSON.stringify({ attribution }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        }).then((response) => {
            if (response.ok) {
                return response.json() as Promise<unknown>;
            }

            throw new Error('Endpoint failed');
        }).then((data) => {
            let remoteAttribution = attribution;
            assertLocalAttributionResponse(data);
            if (data?.localAttribution) {
                remoteAttribution = UtmRecord.fromObject(data.localAttribution);
            }

            Attribution.cacheRemoteAttribution(remoteAttribution);
        }).catch(() => null);
    }

    private static setCookie(name: string, value: string, cookieEnd: Date): void {
        Cookie.set(name, value, { expires: cookieEnd });
    }

    private hasEnteredCheckout(): boolean {
        return Cookie.get(this.checkoutCookieName) === '1';
    }

    private static isInCheckout(): boolean {
        return window?.pageType === 'Checkout';
    }

    private static enrichUtmWithParameters(utm: Utm, parameters: Parameter[]): Utm {
        return utm.withValues({ parameters });
    }

    private static getOtherParametersFromUrlQuery(queryParams: URLSearchParams): Parameter[] {
        const otherParameters: Parameter[] = [];

        Array.from(queryParams.entries()).forEach(([parameter, value]) => {
            if (!(parameter in UrlUtmBuilder.queryMapping)) {
                otherParameters.push({ name: parameter, value });
            }
        });

        return otherParameters;
    }
}
