export default class UrlDataService {
    private readonly userIdParameter = 'user_id';
    private readonly veroIdParameter = 'vero_id';
    private readonly interestsParameter = 'interests';
    private readonly desiredLocaleParameter = 'dl';
    private readonly history?: History;

    readonly originalUrl: URL | null = null;
    readonly userId: string | null = null;
    readonly interests: string[] = [];

    constructor(
        url: URL | null,
        history?: History,
    ) {
        this.history = history;

        if (url) {
            // Copy the URL so its state can never be changed
            this.originalUrl = new URL(url.toString());

            this.userId = this.determineIdentityFromUrl(url);
            this.interests = this.determineInterestsFromUrl(url);
        }
    }

    public getFilteredUrl(): URL | null {
        if (!this.originalUrl) {
            return null;
        }

        const filteredUrl = new URL(this.originalUrl.toString());

        filteredUrl.searchParams.delete(this.userIdParameter);
        filteredUrl.searchParams.delete(this.veroIdParameter);
        filteredUrl.searchParams.delete(this.interestsParameter);
        filteredUrl.searchParams.delete(this.desiredLocaleParameter);

        return filteredUrl;
    }

    public cleanDocumentUrl(): URL | null {
        const filteredUrl = this.getFilteredUrl();

        if (this.history && filteredUrl && this.originalUrl?.toString() !== filteredUrl.toString()) {
            this.history.replaceState(null, '', filteredUrl.toString());

            return filteredUrl;
        }

        return this.originalUrl;
    }

    private determineIdentityFromUrl(url: URL): string | null {
        const userId = url.searchParams.get(this.userIdParameter)
            ?? url.searchParams.get(this.veroIdParameter);

        if (userId === '') {
            return null;
        }

        return userId;
    }

    private determineInterestsFromUrl(url: URL): string[] {
        const interestsString = url.searchParams.get(this.interestsParameter);
        if (interestsString) {
            return interestsString
                .split(',')
                .filter((element) => element !== '');
        }

        return [];
    }
}
