import { z } from 'zod';

export interface Parameter {
    name: string;
    value: string;
}

export interface UtmValues {
    campaign?: string;
    content?: string;
    determinedAt?: number;
    determinedBy?: string;
    medium?: string;
    parameters?: Parameter[];
    source?: string;
    term?: string;
}

const LegacyParameterSchema = z.record(z.string(), z.string());
const ParameterSchema = z.array(z.object({ name: z.string(), value: z.string() }));

export const UtmValuesSchema = z.object({
    campaign: z.string().optional(),
    content: z.string().optional(),
    determinedAt: z.number().optional(),
    determinedBy: z.string().optional(),
    medium: z.string().optional(),
    parameters: z.union([LegacyParameterSchema, ParameterSchema]).optional(),
    source: z.string().optional(),
    term: z.string().optional(),
});

export const NullableUtmValuesSchema = UtmValuesSchema.nullable();

export type UtmKey = 'source' | 'medium' | 'campaign' | 'term' | 'content';

export default class Utm {
    private static readonly unknownDeterminedBy = 'unknown';

    private static readonly unknownSource = '(unknown)';

    private static readonly unknownMedium = '(none)';

    public constructor(
        public readonly determinedBy: string = Utm.unknownDeterminedBy,
        public readonly determinedAt: number = Utm.now(),
        public readonly source: string = Utm.unknownSource,
        public readonly medium: string = Utm.unknownMedium,
        public readonly campaign?: string,
        public readonly term?: string,
        public readonly content?: string,
        public readonly parameters: Parameter[] = [],
    ) {
    }

    /**
     * @param utmValues
     * utmValues may still contain a legacy format where parameters
     */
    public static fromObject(utmValues: z.infer<typeof NullableUtmValuesSchema>): Utm {
        if (typeof utmValues !== 'object' || utmValues === null) {
            return new Utm();
        }

        return new Utm(
            utmValues.determinedBy,
            utmValues.determinedAt,
            utmValues.source,
            utmValues.medium,
            utmValues.campaign,
            utmValues.term,
            utmValues.content,
            Utm.parseParameters(utmValues.parameters) || [],
        );
    }

    public withValues(additionalValues: z.infer<typeof UtmValuesSchema>): Utm {
        const utmValues: UtmValues = {
            campaign: this.campaign,
            content: this.content,
            determinedAt: this.determinedAt,
            determinedBy: this.determinedBy,
            medium: this.medium,
            source: this.source,
            term: this.term,
        };

        // First merge the parameters before they get overwritten
        const currentParameters = this.parameters || [];
        const additionalParameters = Utm.parseParameters(additionalValues.parameters) || [];

        currentParameters.forEach((currentParameter) => {
            // If the current parameter is also present in the additional parameters
            // we skip it, so that the values of the additional parameters overwrite the current ones
            if (!additionalParameters.some((parameter) => parameter.name === currentParameter.name)) {
                additionalParameters.push(currentParameter);
            }
        });

        // Overwrite existing properties with new properties
        Object.assign(utmValues, additionalValues);

        // Then set the merged parameters
        utmValues.parameters = additionalParameters;

        return Utm.fromObject(utmValues);
    }

    public static unknown(determinedBy?: string, now?: number): Utm {
        return new Utm(determinedBy, now);
    }

    public isKnown(): boolean {
        return this.source.length > 0 && this.source !== Utm.unknownSource
            && this.medium.length > 0 && this.medium !== Utm.unknownMedium;
    }

    public equals(utm: Utm): boolean {
        // We don't compare the determinedBy or determinedAt fields
        // as they has no actual bearing on the attribution and are meta fields
        // We also don't compare the parameters because they will be different or missing on most pages
        return this.source === utm.source
            && this.medium === utm.medium
            && this.campaign === utm.campaign
            && this.term === utm.term
            && this.content === utm.content;
    }

    public hasParameter(parameterName: string): boolean {
        if (!this.parameters) {
            return false;
        }

        return this.parameters.some((parameter) => parameter.name === parameterName);
    }

    /**
     * Returns the current timestamp in seconds
     */
    private static now(): number {
        return Math.round(Date.now() / 1000);
    }

    private static parseParameters(parameters?: Parameter[] | Record<string, string>): Parameter[] | undefined {
        if (Array.isArray(parameters) || parameters === undefined) {
            return parameters;
        }

        return Object.entries(parameters).map(([name, value]) => ({ name, value }));
    }
}
