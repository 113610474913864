import { z } from 'zod';
import Utm, { NullableUtmValuesSchema } from './Utm';

export const UtmRecordSchema = z.object({
    attributions: z.record(z.string(), NullableUtmValuesSchema),
}).nullable();

type UtmAttributions = z.infer<typeof UtmRecordSchema>;

function isValidUtmRecordObject(object: unknown): asserts object is UtmAttributions {
    UtmRecordSchema.parse(object);
}

export default class UtmRecord {
    private attributions: Record<string, Utm> = {};

    public setUtm(utm: Utm): void {
        this.attributions[utm.determinedBy] = utm;
    }

    public getUtm(determinedBy: string): Utm | null {
        return this.attributions[determinedBy] ?? null;
    }

    public allUtms(): Utm[] {
        return Object.values(this.attributions);
    }

    public equals(utmRecord: UtmRecord): boolean {
        const otherUtms = utmRecord.allUtms();
        if (otherUtms.length !== this.allUtms().length) {
            return false;
        }

        return otherUtms.every((otherUtm) => {
            const myUtm = this.getUtm(otherUtm.determinedBy);

            return myUtm !== null && otherUtm.equals(myUtm);
        });
    }

    public hasKnownUtm(): boolean {
        return this.allUtms().some((utm) => utm.isKnown());
    }

    public static fromObject(object: unknown): UtmRecord {
        isValidUtmRecordObject(object);

        const utmRecord = new UtmRecord();
        if (!(object?.attributions)) {
            return utmRecord;
        }

        const { attributions } = object;

        Object.values(attributions).forEach((attribution) => {
            utmRecord.setUtm(Utm.fromObject(attribution));
        });

        return utmRecord;
    }
}
