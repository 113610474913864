import UrlDataService from './UrlDataService';
import { document, window } from '../../globals';

const href = document?.location.toString();
const url = href ? new URL(href) : null;

const urlDataService = new UrlDataService(url, window?.history);

if (url) {
    urlDataService.cleanDocumentUrl();
}

export default urlDataService;
