import type Utm from '../Utm';

const enrichUtmForAds = (utm: Utm): Utm => {
    // If there is a gclid but no complete UTM data or the source is google we attribute this to Ads (cpc)
    // Usually there will be no utm parameters on a URL from Google Ads
    // but product feeds from Google shopping are also reused for Ads and they might enter with google / organic instead
    if (utm.hasParameter('gclid') && (!utm.isKnown() || utm.source.toLowerCase() === 'google')) {
        return utm.withValues({ medium: 'cpc', source: 'google' });
    }

    return utm;
};

export { enrichUtmForAds };
