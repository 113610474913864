import { enrichUtmForAds } from './UtmBuilder';
import type { UtmKey, UtmValues } from '../Utm';
import Utm from '../Utm';

export default class UrlUtmBuilder {
    public static readonly queryMapping: Record<string, UtmKey> = {
        utm_campaign: 'campaign',
        utm_content: 'content',
        utm_medium: 'medium',
        utm_source: 'source',
        utm_term: 'term',
    };

    /**
     * @param now The current timestamp in seconds
     */
    public constructor(
        private readonly now: number,
        private readonly currentUrl: URL,
        private readonly referrerUrl: URL | null,
    ) {
    }

    public determineUtm(): Utm {
        const utmValues: UtmValues = { determinedAt: this.now, determinedBy: 'url' };
        const parameters = UrlUtmBuilder.getUrlParametersFromUrlQuery(this.currentUrl.searchParams);

        Object.entries(parameters).forEach(([key, parameter]) => {
            const mappedKey = UrlUtmBuilder.queryMapping[key];
            if (mappedKey) {
                utmValues[mappedKey] = parameter;
            }

            if (key === 'gclid') {
                utmValues.parameters = [{
                    name: 'gclid',
                    value: parameter,
                }];
            }
        });

        let utm = Utm.fromObject(utmValues);
        utm = enrichUtmForAds(utm);

        if (utm.isKnown()) {
            return utm;
        }

        return this.utmFromReferrer();
    }

    private static getUrlParametersFromUrlQuery(queryParams: URLSearchParams): Record<string, string> {
        const utmParameters: Record<string, string> = {};

        Array.from(queryParams.entries()).forEach(([parameter, value]) => {
            utmParameters[parameter] = value;
        });

        return utmParameters;
    }

    private utmFromReferrer(): Utm {
        const utmValues: UtmValues = { determinedAt: this.now, determinedBy: 'url' };

        if (this.referrerUrl === null || this.referrerUrl.hostname === this.currentUrl.hostname) {
            return Utm.fromObject(utmValues);
        }

        utmValues.source = this.referrerUrl?.hostname;
        utmValues.medium = 'referral';

        if (UrlUtmBuilder.isOrganic(this.referrerUrl?.hostname)) {
            utmValues.medium = 'organic';
            /**
             * Officially this may not be a campaign, but this keeps everything in line with
             * how GA sees things.
             */
            utmValues.campaign = '(organic)';
        }

        let utm = Utm.fromObject(utmValues);
        utm = enrichUtmForAds(utm);

        return utm;
    }

    /**
     * We've added the most popular search engines of this list
     *      @see https://support.google.com/analytics/answer/2795821?hl=en.
     */
    private static isOrganic(referrer = ''): boolean {
        return UrlUtmBuilder.urlStartsWith(referrer, 'google.')
            || UrlUtmBuilder.urlStartsWith(referrer, 'goo.')
            || UrlUtmBuilder.urlStartsWith(referrer, 'bing.')
            || UrlUtmBuilder.urlStartsWith(referrer, 'duckduckgo.')
            || UrlUtmBuilder.urlStartsWith(referrer, 'yahoo.')
            || UrlUtmBuilder.urlStartsWith(referrer, 'm.yahoo.');
    }

    private static urlStartsWith(full:string, startsWith: string): boolean {
        return full.indexOf(startsWith) === 0 || full.indexOf(`www.${startsWith}`) === 0;
    }
}
