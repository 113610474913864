import { enrichUtmForAds } from './UtmBuilder';
import type { UtmKey, UtmValues } from '../Utm';
import Utm from '../Utm';

export default class UtmzCookieUtmBuilder {
    private readonly utmzMapping: Record<string, UtmKey> = {
        utmccn: 'campaign',
        utmcmd: 'medium',
        utmcsr: 'source',
        utmctr: 'term',
    };

    /**
     * @param now The current timestamp in seconds
     */
    public constructor(
        private readonly now: number,
    ) {
    }

    public determineUtm(utmzValue: string | undefined): Utm {
        if (!utmzValue) {
            return Utm.unknown('utmz', this.now);
        }

        const utmParts = utmzValue.split('.');

        // The second part contains the timestamp
        const timestamp = parseInt(utmParts[1] ?? '0', 10) ?? null;
        let determinedAt;
        // very basic validating of utmz timestamp
        // basically if it's in the last few years and not in the future (with a day of margin)
        // we'll accept it as a timestamp
        if (timestamp !== null && timestamp > 1500000000 && timestamp < (this.now + 60 * 60 * 24)) {
            determinedAt = timestamp;
        } else {
            determinedAt = this.now;
        }

        const utmValues: UtmValues = { determinedAt, determinedBy: 'utmz' };

        // The remaining parts after the first 4 parts contain the actual attribution
        const utmValue = utmParts.slice(4).join('.');
        if (utmValue) {
            const utmParams = utmValue.split('|');
            utmParams.forEach((utmParam) => {
                const [key, value] = utmParam.split('=');
                if (!key) {
                    return;
                }

                const utmzMappedKey = this.utmzMapping[key];
                if (utmzMappedKey) {
                    utmValues[utmzMappedKey] = value ?? '';
                }

                if (key === 'utmgclid') {
                    utmValues.parameters = [{
                        name: 'gclid',
                        value: value ?? '',
                    }];
                }
            });
        }

        let utm = Utm.fromObject(utmValues);
        utm = enrichUtmForAds(utm);

        return utm;
    }
}
